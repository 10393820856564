export function getRotationAngle(direction: number[]) {
  const [dx, dy] = direction;
  let angle = Math.atan2(dy, dx) + Math.PI / 2;

  if (angle === Math.PI || angle === -Math.PI) {
    angle += Math.PI;
  } else if (angle === 0) {
    angle += Math.PI;
  } else if (angle === Math.PI / 2 || angle === -Math.PI / 2) {
    angle += Math.PI;
  } else if (angle === Math.PI * 1.5 || angle === -Math.PI * 0.5) {
    angle -= Math.PI;
  }
  return angle;
}
