import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { SolanaProvider } from "./solana";
import { GlobalProvider } from "./global";

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Router>
      <SolanaProvider>
        <GlobalProvider>{children}</GlobalProvider>
      </SolanaProvider>
    </Router>
  );
};

export default AppProvider;
