import ControlsImage from "../../assets/images/controls.png";
import { cn } from "../../utils/cn";

type ControlProps = {
  onChangeDirection: (value: number[]) => void;
  side?: "left" | "center" | "right";
};

const Controls: React.FC<ControlProps> = ({
  onChangeDirection,
  side = "left",
}) => {
  return (
    <div
      className={cn("fixed bottom-0 lg:hidden", {
        "right-2": side === "right",
        "left-2:": side === "left",
        "left-1/2 -translate-x-1/2": side === "center",
      })}
    >
      <div className="controls relative max-h-[150px] max-w-28">
        <img
          className="opacity-40 w-40 h-40 object-cover"
          src={ControlsImage}
          alt=""
        />
        <button
          className="absolute top-3 left-1/2 -translate-x-1/2  bg-transparent w-10 h-10"
          onClick={() => onChangeDirection([0, -1])}
        ></button>
        <button
          className="absolute top-1/2 left-0 -translate-y-1/2 bg-transparent w-10 h-10"
          onClick={() => onChangeDirection([-1, 0])}
        ></button>
        <button
          className="absolute top-1/2 -translate-y-1/2 right-0 bg-transparent w-10 h-10"
          onClick={() => onChangeDirection([1, 0])}
        ></button>
        <button
          className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-transparent w-10 h-10"
          onClick={() => onChangeDirection([0, 1])}
        ></button>
      </div>
    </div>
  );
};

export default Controls;
