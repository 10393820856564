import { SIZE } from "../../constants/rules";

export default function drawCoin(
  ctx: CanvasRenderingContext2D,
  coinImage: HTMLImageElement | HTMLCanvasElement | null,
  drawX: number,
  drawY: number,
  currentMovement: any
) {
  if (coinImage) {
    const offset = (SIZE + 1 - SIZE) / 2;

    ctx.drawImage(
      coinImage,
      currentMovement.sx,
      currentMovement.sy,
      currentMovement.px,
      currentMovement.py,
      drawX - offset,
      drawY - offset,
      SIZE + 1,
      SIZE + 1
    );
  }
}
