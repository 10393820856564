import React from "react";
import { BaseWalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { ButtonProps } from "@solana/wallet-adapter-react-ui/lib/types/Button";

const ConnectWallet: React.FC<ButtonProps> = (props) => {
  const customStyle = {
    backgroundColor: "white",
    height: "44px",
    width: "100%",
    color: "#000",
    marginBottom: "4px",
    fontSize: "16px",
    border: "2px solid #000"
  };

  return (
    <BaseWalletMultiButton
      {...props}
      labels={{
        "change-wallet": "Change Wallet",
        connecting: "Connecting ...",
        "copy-address": "Copy Address",
        copied: "Copied",
        disconnect: "Disconnect",
        "has-wallet": "Connect",
        "no-wallet": "Connect Wallet",
      }}
      style={customStyle}
    />
  );
};

export default ConnectWallet;
