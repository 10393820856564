import CoinDisappearing from "../../../assets/images/coin-disappearing.png";
import CoinArising from "../../../assets/images/coin_arising.png";

const coinImage = new Image();
coinImage.src = CoinDisappearing;

const isCoinArising = new Image();
isCoinArising.src = CoinArising;

export { coinImage, isCoinArising };
