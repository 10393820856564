export const coinMovements = [
  { sx: 0, sy: 0, px: 32, py: 32 },
  { sx: 32, sy: 0, px: 32, py: 32 },
  { sx: 64, sy: 0, px: 32, py: 32 },
  { sx: 96, sy: 0, px: 32, py: 32 },
  { sx: 128, sy: 0, px: 32, py: 32 },
];

export const dragonMovementFrames = [
  { sx: 0, sy: 0, px: 32, py: 32 },
  { sx: 32, sy: 0, px: 32, py: 32 },
  { sx: 64, sy: 0, px: 32, py: 32 },
  { sx: 96, sy: 0, px: 32, py: 32 },
  { sx: 128, sy: 0, px: 32, py: 32 },
  { sx: 160, sy: 0, px: 32, py: 32 },
  { sx: 192, sy: 0, px: 32, py: 32 },
  { sx: 224, sy: 0, px: 32, py: 32 },
  { sx: 256, sy: 0, px: 32, py: 32 },
  { sx: 288, sy: 0, px: 32, py: 32 },
];

export const drangonBodyFrames = [
  { sx: 0, sy: 0, px: 32, py: 32 },
  { sx: 32, sy: 0, px: 32, py: 32 },
  { sx: 64, sy: 0, px: 32, py: 32 },
  { sx: 96, sy: 0, px: 32, py: 32 },
  { sx: 128, sy: 0, px: 32, py: 32 },
];

export const drangonMouthFrames = [
  { sx: 0, sy: 0, px: 32, py: 32 },
  { sx: 32, sy: 0, px: 32, py: 32 },
  { sx: 64, sy: 0, px: 32, py: 32 },
  { sx: 96, sy: 0, px: 32, py: 32 },
  { sx: 128, sy: 0, px: 32, py: 32 },
];

export const drangonHeadFrames = [
  { sx: 0, sy: 0, px: 48, py: 48 },
  { sx: 48, sy: 0, px: 48, py: 48 },
  { sx: 96, sy: 0, px: 48, py: 48 },
  { sx: 144, sy: 0, px: 48, py: 48 },
  { sx: 192, sy: 0, px: 48, py: 48 },
];
