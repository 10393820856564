import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";

type GlobalContextType = {
  isMobile: boolean;
  isLandscape: boolean;
  volume: number;
  setVolume: (value: number) => void;
};

const GlobalContext = createContext<GlobalContextType>({
  isMobile: false,
  isLandscape: false,
  volume: 0.5,
  setVolume: () => {},
});

export const useGlobal = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [volume, setVolume] = useState(0.5);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setIsMobile(windowWidth < 1024);
    };

    const handleOrientationChange = () => {
      setIsLandscape(!window.matchMedia("(orientation: landscape)").matches);
    };

    const initialOrientation = () => {
      setIsLandscape(!window.matchMedia("(orientation: portrait)").matches);
    };

    handleResize();
    handleOrientationChange();
    initialOrientation();

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      isMobile,
      isLandscape,
      volume,
      setVolume,
    }),
    [isMobile, isLandscape, setVolume, volume]
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};
