import { Modal } from "../base/modal";
import Coin from "../../assets//gif/moeda_idle.gif";
import IconArrows from "../../assets/images/playAgain.png";
import IconHome from "../../assets/images/homeButton.png";
import GameOverImage from "../../assets/images/game_over_2.png";
import claim from "../../assets/images/claim-button.png";
import { useGlobal } from "../../context/global";
import { cn } from "../../utils/cn";

type GameOverProps = {
  score: number;
  playAgain: () => void;
  onCloseModal: (value: boolean) => void;
};

export const GameOver: React.FC<GameOverProps> = ({
  score,
  playAgain,
  onCloseModal,
}) => {
  const { isMobile, isLandscape } = useGlobal();

  return (
    <Modal className="w-screen h-screen bg-Coin" onClose={onCloseModal}>
      <div className="flex flex-col h-full overflow-hidden items-center gap-4">
        <div className="text-sm flex w-full items-center justify-between text-white">
          <div className="flex items-center">
            <span className="text-2xl w-full whitespace-nowrap lg:text-3xl textShadow font-bold">
              Collected: {score}
            </span>
            <img src={Coin} alt="" className="w-24 h-24 pt-2" />
          </div>
          <div className="flex items-center space-x-2">
            <button onClick={playAgain}>
              <img className="w-12 h-12" src={IconArrows} alt="" />
            </button>
            <a href="/">
              <img className="w-12 h-12" src={IconHome} alt="" />
            </a>
          </div>
        </div>
        <div
          className={cn("flex flex-col items-center gap-y-10 mt-40", {
            "mt-20 gap-y-2 fixed left-1/2 -translate-x-1/2": isMobile && isLandscape,
          })}
        >
          <img
            className={cn("w-full h-40 object-contain lg:object-cover",  {
              "h-28": isMobile && isLandscape,
            })}
            src={GameOverImage}
            alt=""
          />

          <button
            disabled={score <= 0}
            className="w-fit mx-auto disabled:opacity-60 cursor-not-allowed opacity-60"
          >
            <img className="h-24 min-w-36" src={claim} alt="" />
          </button>
        </div>
      </div>
    </Modal>
  );
};
