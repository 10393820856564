import AreaGame from "../components/game";
import Logo from "../assets/gif/Logo.gif";
import HomeIcon from "../assets/images/homeButton.png";
import MaximizeIcon from "../assets/images/maximize.png";
import IconStar from "../assets/images/star.png";
import { Link, useNavigate } from "react-router-dom";
import ConnectWallet from "../components/connectWallet";
import { useCallback, useEffect, useMemo, useState } from "react";
import Settings from "../assets/images/settings.png";
import { useGlobal } from "../context/global";
import { cn } from "../utils/cn";
import React from "react";
import { Tooltip } from "../components/base/tooltip";

export default function Game() {
  const [loadingTransition, setLoadingTransition] = useState(false);
  const { isMobile, isLandscape, volume, setVolume } = useGlobal();
  const [openDropdown, setOpenDropdown] = useState(false);
  const navigate = useNavigate();

  const handleLeaderboardsClick = () => {
    setLoadingTransition(true);
    setTimeout(() => {
      navigate("/leaderboards");
    }, 1500);
  };

  const handleVolumeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVolume = parseFloat(event.target.value);
      setVolume(newVolume);
    },
    [setVolume]
  );

  const requestFullScreen = () => {
    const doc = window.document.documentElement;
    if (doc.requestFullscreen && !document.fullscreenElement) {
      doc.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    if (openDropdown) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openDropdown]);

  const itens = useMemo(
    () => [
      {
        title: "Volume",
        action: (
          <div className="w-full flex items-center justify-between">
            <input
              className="w-3/4 lg:w-1/2 landscape:w-1/2 appearance-none mt-3 bg-[#67461a] h-4"
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
            <div className="lg:hidden">
              <Tooltip content="For a better experience, play in full screen and landscape mode" />
            </div>
          </div>
        ),
      },
      {
        title: "",
        action: <ConnectWallet />,
      },
    ],
    [handleVolumeChange, volume]
  );

  return (
    <div className={"w-full h-screen custom_bg relative"}>
      <div className="flex justify-between items-center px-6 w-full max-h-[120px] lg:max-h-[160px]">
        <a href="/">
          <img
            className={cn("w-40 h-40 md:w-56 md:h-56 md:mt-6 object-contain", {
              hidden: isMobile && isLandscape,
            })}
            src={Logo}
            alt=""
          ></img>
        </a>
        <div className="flex items-center landscape:ml-auto md:mt-4">
          <Link to="/">
            <img
              className={cn("w-12 h-12", {
                "w-10 h-10 mt-2": isLandscape && isMobile,
              })}
              src={HomeIcon}
              alt=""
            />
          </Link>

          <button
            onClick={handleLeaderboardsClick}
            className={cn(
              "border-[2px] hidden lg:flex items-center ml-2 w-12 h-12 rounded bg-[#714306] border-black",
              {
                "w-10 h-10": isLandscape && isMobile,
              }
            )}
          >
            <img
              className="w-6 h-6 md:w-8 md:h-8 m-auto"
              src={IconStar}
              alt=""
            />
          </button>

          <div></div>

          <button
            onClick={() => setOpenDropdown(!openDropdown)}
            className={cn(
              "border-[2px] flex items-center ml-2 w-12 h-12 rounded bg-[#714306] border-black relative z-[999]",
              {
                "w-10 h-10 mt-2": isLandscape && isMobile,
              }
            )}
          >
            <img
              className="w-6 h-6 md:w-8 md:h-8 m-auto"
              src={Settings}
              alt=""
            />
          </button>
        </div>
      </div>
      <AreaGame />
      {openDropdown && (
        <div
          className={cn(
            "absolute top-0 right-0 w-full lg:w-1/3 h-full max-h-[400px] lg:max-h-full z-[998] bg-gradient-to-b from-[#483112] to-orange-800/60  shadow-2xl",
            isMobile ? "animate-fade-down" : "animate-fade-right"
          )}
        >
          <button
            className="absolute right-24 top-10 lg:top-16 max-[1024px]:landscape:top-5"
            onClick={requestFullScreen}
          >
            <img width={42} height={42} src={MaximizeIcon} alt="" />
          </button>
          <ul
            className={cn(
              "p-5 flex flex-col justify-between h-[300px] lg:max-h-[200px] lg:mt-14",
              {
                "mt-20": isMobile,
                "flex-col-reverse": !isMobile,
              }
            )}
          >
            {itens.map((item, key) => (
              <div key={key}>
                <li className="text-[#FD8209] text-lg font-semibold whitespace-nowrap">
                  {item.title}
                </li>
                {item.action}
              </div>
            ))}
          </ul>
        </div>
      )}
      {openDropdown && (
        <div className="opacity-70 fixed inset-0 bg-black z-[997]"></div>
      )}
      {loadingTransition && <div className="modal-background-transition"></div>}
    </div>
  );
}
