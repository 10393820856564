"use client";

import { useCallback, useEffect } from "react";
import type { ReactNode } from "react";
import { cn } from "../../../utils/cn";

export type ModalProps = {
  title?: string;
  children: ReactNode;
  onClose: (value: boolean) => void;
  className?: string;
};

export function Modal({ title, children, onClose, className }: ModalProps) {
  const handleCloseEscKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose(false);
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";

    document.addEventListener("keydown", handleCloseEscKey);

    return () => {
      document.removeEventListener("keydown", handleCloseEscKey);
      document.body.style.overflow = "auto";
    };
  }, [handleCloseEscKey]);

  return (
    <div className="fixed left-0 top-0 z-30 grid h-screen w-screen place-content-center items-center backdrop-blur-lg">
      <div
        className={cn(
          "w-full inset-0 px-7 z-40 animate-fade-up animate-duration-300 animate-once duration-300 animate-ease-linear",
          className
        )}
      >
        <div
          className={cn("flex items-center justify-between space-x-5", {
            "mb-4": title,
          })}
        >
          {title && (
            <span className="text-lg font-semibold text-white">{title}</span>
          )}
        </div>

        <div className="h-auto flex flex-col justify-center w-full overflow-y-auto max-h-[580px]">
          {children}
        </div>
      </div>
    </div>
  );
}
