import {
  dragonMovementFrames,
  drangonHeadFrames,
} from "../../../constants/movements";
import { BLOCK_SIZE, SIZE } from "../../../constants/rules";
import { getRotationAngle } from "../../../utils/rotationAngle";
import { snakeBodyArising, snakeMouthOpen } from "../images";

export function drawDragonHead(
  ctx: CanvasRenderingContext2D,
  snake: number[][],
  direction: number[],
  isMouthOpen: boolean,
  index: number
) {
  const headSegment = snake[0];
  const [x, y] = headSegment;
  const currentFrame = drangonHeadFrames[index % drangonHeadFrames.length];

  let drawX = x;
  let drawY = y;

  if (direction[0] > 0 || direction[1] < 0) {
    drawX++;
    drawY++;
  }

  ctx.save();
  ctx.translate(drawX, drawY);
  ctx.rotate(getRotationAngle(direction));
  ctx.clearRect((BLOCK_SIZE - SIZE) / 2, (BLOCK_SIZE - SIZE) / 2, SIZE, SIZE);

  if (isMouthOpen) {
    ctx.drawImage(
       snakeMouthOpen,
      currentFrame.sx,
      currentFrame.sy,
      currentFrame.px,
      currentFrame.py,
      (BLOCK_SIZE - SIZE) / 2,
      (BLOCK_SIZE - SIZE) / 2,
      SIZE,
      SIZE
    );
  }
  ctx.restore();
}

export function drawDragonBody(
  ctx: CanvasRenderingContext2D,
  snake: number[][],
  index: number
) {
  const bodySegment = snake[snake.length - 2];
  const [x, y] = bodySegment;

  const currentFrame =
    dragonMovementFrames[index % dragonMovementFrames.length];

  ctx.drawImage(
    snakeBodyArising,
    currentFrame.sx,
    currentFrame.sy,
    currentFrame.px,
    currentFrame.py,
    x,
    y,
    SIZE,
    SIZE
  );
}
