import { Link } from "react-router-dom";
import LeaderBoardImage from "../../assets/images/leaderboards.png";
import ScoreBox from "../../assets/images/Score-leader.png";
import HomeIcon from "../../assets/images/homeButton.png";
import { cn } from "../../utils/cn";
import { useGlobal } from "../../context/global";

const leaderboardDataMock = [
  { hash: "6tu1234567890def456", score: 300 },
  { hash: "d3p4567890abc123456", score: 200 },
  { hash: "u6h7890abc123456789", score: 150 },
  { hash: "ub7801234567890mno45", score: 150 },
  { hash: "kt467890stu123456789", score: 147 },
  { hash: "vwx01234567890yza678", score: 130 },
  { hash: "bcd1234567890efg123", score: 120 },
  { hash: "hij4567890klm012345", score: 100 },
  { hash: "nop7890qrs123456789", score: 98 },
  { hash: "tuv01234567890wxy01", score: 39 },
  { hash: "7uA9b4567890cde123456", score: 27 },
  { hash: "l4j4567890cde123456", score: 24 },
  { hash: "ab4567890cde123456", score: 20 },
  { hash: "7xb4567890cde123456", score: 12 },
  { hash: "py7b4567890cde123456", score: 8 },
  { hash: "1v8kb4567890cde123456", score: 6 },
];

export const Leaderboards: React.FC = () => {
  const { isMobile, isLandscape } = useGlobal();

  return (
    <div
      className={cn(
        "bg-[#ff8104] w-screen p-4",
        isMobile && isLandscape ? "h-auto" : "h-screen"
      )}
    >
      <div className="float-left w-full mb-5">
        <Link to="/">
          <img className="w-12 h-12" src={HomeIcon} alt="" />
        </Link>
      </div>
      <div className="flex flex-col w-full items-center justify-center">
        <img
          className="w-full max-w-[400px] object-cover"
          src={LeaderBoardImage}
          alt=""
        />
        <div className="w-full relative max-w-[400px] mt-5">
          <img src={ScoreBox} alt="" />
          <div className="flex absolute text-sm p-10 opacity-80 top-0 w-full text-white items-center justify-between mb-3">
            <span>#</span>
            <span>Hash</span>
            <span className="text-[#fad748] font-semibold">Score</span>
          </div>
          <ul className="absolute px-10 max-h-[350px] my-8 mt-16 overflow-auto top-0 left-0 right-0 bottom-0">
            {leaderboardDataMock.map((item, key) => (
              <div
                key={key}
                className="flex text-white items-center justify-between"
              >
                <span className="opacity-70">{key + 1}</span>
                <li className="truncate max-w-32 text-sm">{item.hash}</li>
                <span className="text-[#fad748] font-semibold">
                  {item.score}
                </span>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
