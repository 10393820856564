import { useEffect, useRef, useState } from "react";
import Indicator from "../../../assets/images/information.png";

export function Tooltip({ content }: { content: React.ReactNode }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setShowTooltip(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-fit z-20" ref={tooltipRef}>
      <button onClick={() => setShowTooltip(!showTooltip)} type="button">
        <img src={Indicator} width={24} height={24} alt="" />
      </button>
      {showTooltip && (
        <div className="absolute top-10 right-0 -left-32 z-[999] w-full min-w-[300px] max-w-[300px] text-opacity-70 -translate-x-1/2 rounded-lg bg-[#351c0cc5] p-2 text-white text-left shadow-lg text-xs transition-all ease-in">
          {content}
        </div>
      )}
    </div>
  );
}
