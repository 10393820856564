import { Link } from "react-router-dom";
import Coins from "../assets/gif/coins-down.gif";
import Background from "../assets/gif/Logo.gif";
import ConnectWallet from "../components/connectWallet";
import { useWallet } from "@solana/wallet-adapter-react";
import Play from "../assets/images/play_button.png";
import Sponsors from "../assets/images/button-sponsors.png";
import { useGlobal } from "../context/global";
import { cn } from "../utils/cn";

export default function Home() {
  const wallet = useWallet();
  const { isMobile, isLandscape } = useGlobal();

  return (
    <div className="bg-[#ff8104] w-full h-screen relative">
      <div className="flex float-right m-4 relative z-20">
        <ConnectWallet />
      </div>
      <img
        className="hidden md:block w-3/4 mx-auto z-0 absolute top-0 left-0 right-0 bottom-0 h-full"
        src={Coins}
        alt=""
      />
      <div className="absolute min-w-[300px] z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center flex-col">
        <img
          className={cn("w-full h-full object-cover", {
            "w-2/4": isMobile && isLandscape,
          })}
          src={Background}
          alt=""
        />
        <Link to={"/game"}>
          <button
            disabled={!wallet.connected}
            className={cn(
              "w-full max-w-[350px] max-h-20 disabled:opacity-60 disabled:cursor-not-allowed hover:scale-105",
              { "w-2/4 ml-20": isMobile && isLandscape }
            )}
          >
            <img src={Play} alt="" />
          </button>
        </Link>

        <button
          className={cn(
            "hover:scale-105 w-full max-w-[350px] max-h-20 cursor-default",
            { "w-2/4": isMobile && isLandscape }
          )}
        >
          <img src={Sponsors} alt="" />
        </button>
      </div>
    </div>
  );
}
