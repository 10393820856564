import React, { useCallback, useEffect, useRef, useState } from "react";
import useInterval from "../utils/useInterval";
import Map from "../assets/images/Tilemap.png";
import AppleLogo from "../assets/images/coin_sprite.png";

import {
  coinMovements,
  drangonBodyFrames,
  drangonHeadFrames,
  drangonMouthFrames,
} from "../constants/movements";
import CoinArising from "../assets/images/coin_arising.png";
import Coin from "../assets//gif/moeda_idle.gif";
import { cn } from "../utils/cn";
import { snakeHead, snakeBodyImage, snakeTail } from "./dragon/images";
import {
  BLOCK_SIZE,
  CANVAS_X,
  CANVAS_Y,
  SCALE,
  SIZE,
  TIME_DELAY,
} from "../constants/rules";
import { drawDragonBody, drawDragonHead } from "./dragon/parts";
import drawCoin from "./coin";
import { getRotationAngle } from "../utils/rotationAngle";
import { coinImage, isCoinArising } from "./coin/images";
import { GameOver } from "./gameOver";
import Controls from "./controls";
import { useGlobal } from "../context/global";
import { Link } from "react-router-dom";

const initialSnake = [
  [20, 18],
  [20, 17],
];

const initialApple = [
  Math.floor(Math.random() * (CANVAS_X / SCALE)),
  Math.floor(Math.random() * (CANVAS_Y / SCALE)),
];

const coinAudio = new Audio(require("../assets/audio/Bonk_2_Earn_COIN_2.mp3"));
const gameAudio = new Audio(require("../assets/audio/Bonk_2_Earn_SONG.mp3"));
const deadAudio = new Audio(require("../assets/audio/Bonk_2_Earn_DEAD.mp3"));

const AreaGame: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [snake, setSnake] = useState(initialSnake);
  const [apple, setApple] = useState(initialApple);
  const [direction, setDirection] = useState([0, 1]);
  const [delay, setDelay] = useState<number | null>(null);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [index, setIndex] = useState(0);
  const [isCoinAppearing, setIsCoinAppearing] = useState(false);
  const [isBodyAppearing, setIsBodyAppearing] = useState(false);
  const [isMouthOpen, setIsMouthOpen] = useState(false);
  const [scoreAnimation, setScoreAnimation] = useState(false);
  const [audioStarted, setAudioStarted] = useState(false);
  const [showTransition, setShowTransition] = useState(false);
  const { isMobile, isLandscape, volume } = useGlobal();

  const mapImage = new Image();
  mapImage.src = Map;

  useInterval(() => runGame(), delay);

  useEffect(() => {
    const playAudioOnInteraction = () => {
      if (!audioStarted) {
        setAudioStarted(true);
        gameAudio.play();
        setIsGameStarted(true);
      }
    };

    const handleGameAudioEnded = () => {
      gameAudio.currentTime = 0;
      gameAudio.play();
    };

    document.addEventListener("click", playAudioOnInteraction);
    gameAudio.addEventListener("ended", handleGameAudioEnded);

    return () => {
      document.removeEventListener("click", playAudioOnInteraction);
      gameAudio.removeEventListener("ended", handleGameAudioEnded);
    };
  }, [audioStarted, setIsGameStarted, volume]);

  useEffect(() => {
    gameAudio.volume = volume;
  }, [volume]);

  useEffect(() => {
    let fruit = document.getElementById("fruit") as HTMLCanvasElement;

    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.setTransform(SCALE, 0, 0, SCALE, 0, 0);
        ctx.clearRect(0, 0, CANVAS_X, CANVAS_Y);

        ctx.fillStyle = "#a3d001";
        snake.forEach(([x, y], index) => {
          const imageToDraw =
            index === 0
              ? snakeHead
              : index === snake.length - 1
              ? snakeTail
              : snakeBodyImage;

          let drawX = x;
          let drawY = y;

          if (direction[0] > 0 || direction[1] < 0) {
            drawX++;
            drawY++;
          }

          const rotationAngle = getRotationAngle(direction);
          const currentMovement =
            index === 0 ? drangonHeadFrames : drangonBodyFrames;
          const currentFrame = currentMovement[index % currentMovement.length];
          const offset =
            index === 0 ? (BLOCK_SIZE - SIZE) / 1 : (BLOCK_SIZE - SIZE) / 2;
          const size = index === 0 ? 3 : SIZE;

          ctx.save();
          ctx.translate(drawX, drawY);
          ctx.rotate(rotationAngle);
          ctx.drawImage(
            imageToDraw,
            currentFrame.sx,
            currentFrame.sy,
            currentFrame.px,
            currentFrame.py,
            offset,
            offset,
            size,
            size
          );
          ctx.restore();

          if (isMouthOpen) {
            drawDragonHead(ctx, snake, direction, isMouthOpen, index);
          }

          if (isBodyAppearing && index === snake.length - 2) {
            drawDragonBody(ctx, snake, index);
          }
        });

        const currentMovement = coinMovements[index];

        const drawX = apple[0] + (BLOCK_SIZE - SIZE) / 2;
        const drawY = apple[1] + (BLOCK_SIZE - SIZE) / 2;

        if (!isCoinAppearing) {
          drawCoin(ctx, fruit, drawX, drawY, currentMovement);
        }

        if (isCoinAppearing && CoinArising) {
          drawCoin(ctx, isCoinArising, drawX, drawY, currentMovement);
        }
      }
    }
  }, [
    snake,
    apple,
    gameOver,
    direction,
    index,
    isBodyAppearing,
    isCoinAppearing,
    isMouthOpen,
  ]);

  const draw = useCallback(() => {
    setIndex((prevIndex) => (prevIndex + 1) % coinMovements.length);
  }, []);

  useEffect(() => {
    const interval = setInterval(draw, 120);
    return () => clearInterval(interval);
  }, [draw]);

  const drawDragon = useCallback(() => {
    setIndex((prevIndex) => (prevIndex + 1) % drangonMouthFrames.length);
  }, []);

  useEffect(() => {
    const interval = setInterval(drawDragon, 100);
    return () => clearInterval(interval);
  }, [drawDragon]);

  useEffect(() => {
    if (delay !== null) {
      play();
    }
  }, [delay]);

  function handleSetScore() {
    if (score > Number(localStorage.getItem("snakeScore"))) {
      localStorage.setItem("snakeScore", JSON.stringify(score));
    }
  }

  function play() {
    setSnake(initialSnake);
    setApple(initialApple);
    setDelay(TIME_DELAY);
    setScore(0);
    setGameOver(false);
  }

  function checkCollision(head: number[]) {
    for (let i = 0; i < head.length; i++) {
      if (
        head[i] < 0 ||
        head[i] * SCALE >= CANVAS_X ||
        head[i] * SCALE < 0 ||
        head[i] >= CANVAS_Y / SCALE
      )
        return true;
    }
    for (const s of snake) {
      if (head[0] === s[0] && head[1] === s[1]) return true;
    }
    return false;
  }

  function appleAte(newSnake: number[][]) {
    if (newSnake[0][0] === apple[0] && newSnake[0][1] === apple[1]) {
      let coord = [
        Math.floor(Math.random() * (CANVAS_X / SCALE)),
        Math.floor(Math.random() * (CANVAS_Y / SCALE)),
      ];
      let newApple = coord;
      setScore(score + 1);
      setApple(newApple);
      return true;
    }

    return false;
  }

  function runGame() {
    if (!isGameStarted) {
      return;
    }
    const newSnake = [...snake];
    const newSnakeHead = [
      newSnake[0][0] + direction[0],
      newSnake[0][1] + direction[1],
    ];
    newSnake.unshift(newSnakeHead);

    if (checkCollision(newSnakeHead)) {
      deadAudio.play();
      setDelay(null);
      setShowTransition(true);
      gameAudio.volume = volume;
      handleSetScore();
      setTimeout(() => {
        setGameOver(true);
      }, 2000);
      return;
    }

    let previousApplePosition = [...apple];
    if (!appleAte(newSnake)) {
      newSnake.pop();
    } else {
      setIsMouthOpen(true);
      coinAudio.play();
      setIsBodyAppearing(true);
      const ctx = canvasRef.current?.getContext("2d");
      if (ctx) {
        let frameIndex = 0;
        const coinDisappearInterval = setInterval(() => {
          const currentFrame = coinMovements[frameIndex];

          const drawX = previousApplePosition[0] + (BLOCK_SIZE - SIZE) / 2;
          const drawY = previousApplePosition[1] + (BLOCK_SIZE - SIZE) / 2;

          ctx.clearRect(drawX, drawY, SIZE, SIZE);
          ctx.drawImage(
            coinImage,
            currentFrame.sx,
            currentFrame.sy,
            currentFrame.px,
            currentFrame.py,
            drawX,
            drawY,
            SIZE,
            SIZE
          );
          frameIndex++;
          if (frameIndex === coinMovements.length) {
            clearInterval(coinDisappearInterval);
            setIsCoinAppearing(false);
            setIsBodyAppearing(false);
          }
        }, 100);
      }
      setTimeout(() => {
        setIsMouthOpen(false);
      }, 500);
      setIsCoinAppearing(true);
    }
    setSnake(newSnake);
  }

  function changeDirection(e: React.KeyboardEvent<HTMLDivElement>) {
    switch (e.key) {
      case "ArrowLeft":
        setDirection([-1, 0]);
        setDelay(TIME_DELAY);
        break;
      case "ArrowUp":
        setDirection([0, -1]);
        setDelay(TIME_DELAY);
        break;
      case "ArrowRight":
        setDirection([1, 0]);
        setDelay(TIME_DELAY);
        break;
      case "ArrowDown":
        setDirection([0, 1]);
        setDelay(TIME_DELAY);
        break;
    }
    setIsGameStarted(true);
  }

  function handleTryAgain() {
    setSnake(initialSnake);
    setShowTransition(false);
    gameAudio.volume = volume;
    setApple(initialApple);
    setScore(0);
    setGameOver(false);
    setDirection([0, 1]);
    setIsGameStarted(false);
  }

  function handleChangeDirection(direction: number[]) {
    setDirection(direction);
    setDelay(TIME_DELAY);
    setIsGameStarted(true);
  }

  useEffect(() => {
    const scoreElement = document.getElementById("score");
    if (scoreElement) {
      setScoreAnimation(true);
      setTimeout(() => {
        setScoreAnimation(false);
      }, 1000);
    }
  }, [score]);

  return (
    <div
      className="max-w-[1440px] 2xl:w-full relative flex flex-col px-3 lg:px-4"
      onKeyDown={(e) => changeDirection(e)}
    >
      <div className="w-full md:mt-10 max-[768px]:flex items-center justify-between pr-4">
        <div className="max-[768px]:flex items-center gap-x-4">
          <div
            className={cn("flex z-10 relative items-center", {
              "fixed top-1/3 -translate-y-1/2 right-8": isMobile && isLandscape,
            })}
          >
            <img
              src={Coin}
              alt="coin"
              className="w-14 object-cover h-14 lg:w-20 lg:h-20"
            />
            <span
              id="score"
              className={cn(
                "font-bold textShadow mb-1 max-[768px]:text-xl w-full",
                scoreAnimation ? "jump" : ""
              )}
            >
              {score}
            </span>
          </div>
        </div>
        <Link to={"/leaderboards"}>
          <div
            className={cn(
              "bg-[#b52e2e] w-fit h-fit text-white font-bold rounded p-2 shadow-lg",
              {
                "fixed right-4 top-1/2 -translate-y-1/2 text-xs":
                  isMobile && isLandscape,
              }
            )}
          >
            <h2 className="highScore">
              High Score: {localStorage.getItem("snakeScore")}
            </h2>
          </div>
        </Link>
      </div>

      <img
        id="fruit"
        src={AppleLogo}
        alt="fruit"
        width="30"
        className="apple-animation hidden"
      />
      <canvas
        className={cn(
          "playArea max-[430px]:mt-8 mx-auto rounded fixed z-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
          {
            "landscape:w-[624px] landscape:h-[100%] left-0 translate-x-0":
              isLandscape && isMobile,
          }
        )}
        ref={canvasRef}
        width={`${CANVAS_X}px`}
        height={`${CANVAS_Y}px`}
        tabIndex={0}
      />

      {showTransition && (
        <div className="modal-background-transition">
          {gameOver && (
            <GameOver
              score={score}
              onCloseModal={handleTryAgain}
              playAgain={handleTryAgain}
            />
          )}
        </div>
      )}
      {(isMobile || isLandscape) && (
        <Controls
          side={isLandscape ? "right" : "center"}
          onChangeDirection={handleChangeDirection}
        />
      )}
    </div>
  );
};

export default AreaGame;
