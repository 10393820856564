import DragonHead from "../../../assets/images/head.png";
import DragonBody from "../../../assets/images/dragonBody.png";
import DragonTail from "../../../assets/images/dragon_tail.png";
import DragonBodyArising from "../../../assets/images/dragonBodyArising.png";
import DragonHeadBiting from "../../../assets/images/bitting-mouth.png";

const snakeHead = new Image();
snakeHead.src = DragonHead;

const snakeBodyImage = new Image();
snakeBodyImage.src = DragonBody;

const snakeTail = new Image();
snakeTail.src = DragonTail;

const snakeBodyArising = new Image();
snakeBodyArising.src = DragonBodyArising;

const snakeMouthOpen = new Image();
snakeMouthOpen.src = DragonHeadBiting;

export {
  snakeHead,
  snakeBodyImage,
  snakeTail,
  snakeBodyArising,
  snakeMouthOpen,
};
